// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".errorMessage_error_message_text__3cSeI {\n  font-size: 12px;\n  text-align: left;\n  margin: 0;\n  color: #ff5959; }\n", "",{"version":3,"sources":["webpack://./src/components/shared/error-message/errorMessage.module.scss","webpack://./src/assets/scss/variables.scss"],"names":[],"mappings":"AAEA;EACE,eCMY;EDLZ,gBAAgB;EAChB,SAAS;EACT,cCFa,EAAA","sourcesContent":["@import \"../../../assets/scss/variables.scss\";\n\n.error_message_text {\n  font-size: $font-12;\n  text-align: left;\n  margin: 0;\n  color: $error;\n}\n","$accentColor: #1c75bc;\n$backgroundColor: #f0f0f0;\n$primaryColor: #606161;\n$secondaryColor: #606161;\n$error: #ff5959;\n$success: #2eb086;\n$white: #ffffff;\n\n$font-10: 10px;\n$font-12: 12px;\n$font-14: 14px;\n$font-16: 16px;\n$font-18: 18px;\n$font-20: 20px;\n$font-24: 24px;\n$font-28: 28px;\n$font-32: 32px;\n\n$regular: 400;\n$medium: 500;\n$semibold: 600;\n\n$firaSans: \"Fira Sans\", sans-serif;\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"error_message_text": "errorMessage_error_message_text__3cSeI"
};
export default ___CSS_LOADER_EXPORT___;
