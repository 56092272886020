// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".globalStyles_background__iRmc6 {\n  min-height: 100vh;\n  height: 100vh;\n  overflow: auto;\n  background-color: #fff; }\n", "",{"version":3,"sources":["webpack://./src/styles/globalStyles.module.scss"],"names":[],"mappings":"AAEA;EACE,iBAAiB;EACjB,aAAa;EACb,cAAc;EACd,sBAAsB,EAAA","sourcesContent":["@import \"../assets/scss/variables.scss\";\n\n.background {\n  min-height: 100vh;\n  height: 100vh;\n  overflow: auto;\n  background-color: #fff;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"background": "globalStyles_background__iRmc6"
};
export default ___CSS_LOADER_EXPORT___;
