// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".button_btn_back__bqoNg {\n  border-radius: 36px;\n  text-align: center;\n  height: 40px;\n  max-width: 300px;\n  min-width: 150px;\n  padding: 0 10px; }\n\n.button_button_text__9xNnw {\n  margin: 0;\n  font-size: 14px;\n  font-weight: 500;\n  text-align: center; }\n\n.button_btn_back__bqoNg:disabled {\n  max-width: 300px;\n  min-width: 150px;\n  height: 40px;\n  opacity: 0.7;\n  cursor: no-drop;\n  background-color: #606161; }\n", "",{"version":3,"sources":["webpack://./src/components/shared/button/button.module.scss","webpack://./src/assets/scss/variables.scss"],"names":[],"mappings":"AAEA;EACE,mBAAmB;EACnB,kBAAkB;EAClB,YAAY;EACZ,gBAAgB;EAChB,gBAAgB;EAChB,eAAe,EAAA;;AAGjB;EACE,SAAS;EACT,eCHY;EDIZ,gBCKU;EDJV,kBAAkB,EAAA;;AAGpB;EACE,gBAAgB;EAChB,gBAAgB;EAChB,YAAY;EACZ,YAAY;EACZ,eAAe;EACf,yBCrBsB,EAAA","sourcesContent":["@import \"../../../assets/scss/variables.scss\";\n\n.btn_back {\n  border-radius: 36px;\n  text-align: center;\n  height: 40px;\n  max-width: 300px;\n  min-width: 150px;\n  padding: 0 10px;\n}\n\n.button_text {\n  margin: 0;\n  font-size: $font-14;\n  font-weight: $medium;\n  text-align: center;\n}\n\n.btn_back:disabled {\n  max-width: 300px;\n  min-width: 150px;\n  height: 40px;\n  opacity: 0.7;\n  cursor: no-drop;\n  background-color: $secondaryColor;\n}\n","$accentColor: #1c75bc;\n$backgroundColor: #f0f0f0;\n$primaryColor: #606161;\n$secondaryColor: #606161;\n$error: #ff5959;\n$success: #2eb086;\n$white: #ffffff;\n\n$font-10: 10px;\n$font-12: 12px;\n$font-14: 14px;\n$font-16: 16px;\n$font-18: 18px;\n$font-20: 20px;\n$font-24: 24px;\n$font-28: 28px;\n$font-32: 32px;\n\n$regular: 400;\n$medium: 500;\n$semibold: 600;\n\n$firaSans: \"Fira Sans\", sans-serif;\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"btn_back": "button_btn_back__bqoNg",
	"button_text": "button_button_text__9xNnw"
};
export default ___CSS_LOADER_EXPORT___;
